import React, { useEffect } from "react"
import { ButtonPrimary } from "../../UI/Button/Button"
import { FoudroyerLink } from "../FoudroyerLink/FoudroyerLink"
import { Loader } from "../Loader/Loader"
import { connector, ContainerProps } from "./containers/Protected.containers"

export const Wrapper: React.FC<ContainerProps> = (props) => {
  useEffect(() => {
    props.onMount()
  }, [])

  if (props.isInitialised && props.isAuthenticated) return <>{props.children}</>
  if (props.isInitialised && !props.isAuthenticated) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div>
          You are not authenticated
          <FoudroyerLink to="/">
            <ButtonPrimary>Go to home page</ButtonPrimary>
          </FoudroyerLink>
        </div>
      </div>
    )
  }

  return (
    <div className="flex h-screen items-center justify-center">
      <Loader />
    </div>
  )
}

export const Protected = connector(Wrapper)
