import { actions } from "@/redux/actions"
import { RootState } from "@/redux/store"
import { ReactNode } from "react"
import { connect, ConnectedProps } from "react-redux"

const mapState = (state: RootState, props: { children: ReactNode }) => ({
  isInitialised: state.auth.initialised,
  isAuthenticated: state.auth.authenticated,
  children: props.children,
})

const mapDispatch = (dispatch: any) => ({
  onMount: () => {
    dispatch(actions.auth.$isAuthenticatedOrRedirect())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
